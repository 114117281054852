:root {

  --color-background: #4C1D95;
  --color-background-shade: rgba(0, 0, 0, .4);
  --color-text: #F9FAFB;
  --color-accent: #EC4899;
  --color-primary: #7C3AED;
  --color-primary-focus: #6D28D9;
  --color-ava-background: white;
  --color-ava-background-text: #111827;

  --font-text: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --font-heading: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: inherit;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--color-background);
  font-family: var(--font-text);

  color: var(--color-text);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--font-heading);
}

.header,
.header__hero-image img,
.header__layout {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  position: absolute;

  /* Р¤РёРєСЃ РєР°СЂС‚РёРЅРєРё РґР»СЏ РїРѕСЃР»РµРґРЅРµРіРѕ С…СЂРѕРјР° */
  aspect-ratio: auto;
}

.header {
  background-color: var(--dark);
  position: relative;
}



.header__hero-image img {
  user-select: none;
  object-fit: cover;
  touch-action: manipulation;
}

.header__layout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-background-shade); 
}

.profile__sign {
  display: block;
  margin: 0 auto;
  margin-bottom: 1rem;
  color: var(--color-text);
}

@keyframes sign {
  from {
    stroke-dashoffset: calc(var(--path-length) * 1px);
  }

  to {
    stroke-dashoffset: 0px;
  }
}

.profile__sign path {
  animation: sign 2s ease 200ms 1 normal forwards;
}

.profile__avatar {
  --ava-size: 256px;
  --ava-border: 0.3rem;
  
  width: var(--ava-size);
  height: var(--ava-size);

  transform-style: preserve-3d;
  margin: 0 auto;
  transform: rotate3d(0.01, 1, 0, 0deg);
  transition: 500ms transform ease-in-out;
  position: relative;
  
}

.profile__avatar--front {
  transform: rotate3d(0.01, 1, 0, 0deg);
}

.profile__avatar--back {
  transform: rotate3d(0, 1, 0, 180deg);
}

.profile__avatar > * {
  position: absolute;
  width: var(--ava-size);
  height: var(--ava-size);

  top: calc(-1 * var(--ava-border));
  left: calc(-1 * var(--ava-border));

  border-width: var(--ava-border);
  border-style: solid;
  background-color: var(--color-ava-background);
  border-radius: var(--ava-size);
  overflow: hidden;
  backface-visibility: hidden;
  
  padding: 0;
}

.profile__avatar-front {
  transform: rotate3d(0, 1, 0, 0deg);
}

.profile__picture-button {
  appearance: none;
  cursor: pointer;

  border-color: var(--color-ava-background);
  transition: 200ms border-color ease;
}

.profile__picture-button:hover, 
.profile__picture-button:focus {
  border-color: var(--color-accent);
}

.profile__picture {
  transform: translate(calc(-1 * var(--ava-border)),  calc(-1 * var(--ava-border)));
}

.profile__avatar-back {
  transform: rotate3d(0, 1, 0, 180deg);
  display: flex;
  flex-direction: column;
  border-color: var(--color-accent);
}

.profile__avatar-back-title {
  color: var(--color-ava-background-text);
  text-align: center;
}

.profile__links-cloud {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 0;
  margin: 0;
}

.profile-link {
  display: block;
  margin: 0 auto;
  text-align: center;
}

.profile__avatar-back-button {
  appearance: none;
  border-radius: 0.25rem;
  
  background-color: var(--color-primary);
  color: var(--color-text);
  font-weight: bold;
  font-size: 1rem;

  cursor: pointer;
  user-select: none;

  border: none;
  display: block;

  padding: 0.8rem 1rem;
  margin: 0 auto;
  
  transition: 200ms background-color ease;
}

.profile__avatar-back-button:hover,
.profile__avatar-back-button:focus {
  background-color: var(--color-primary-focus);
}

.profile__subtext {
  max-width: 400px;
  text-align: center;
  padding: 0 1rem;
}