:root {
  --color-background: #4c1d95;
  --color-background-shade: #0006;
  --color-text: #f9fafb;
  --color-accent: #ec4899;
  --color-primary: #7c3aed;
  --color-primary-focus: #6d28d9;
  --color-ava-background: white;
  --color-ava-background-text: #111827;
  --font-text: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --font-heading: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

*, :before, :after {
  box-sizing: border-box;
  font-family: inherit;
}

body {
  background-color: var(--color-background);
  font-family: var(--font-text);
  color: var(--color-text);
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-heading);
}

.header, .header__hero-image img, .header__layout {
  min-height: 100vh;
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  aspect-ratio: auto;
  position: absolute;
}

.header {
  background-color: var(--dark);
  position: relative;
}

.header__hero-image img {
  -webkit-user-select: none;
  user-select: none;
  object-fit: cover;
  touch-action: manipulation;
}

.header__layout {
  background-color: var(--color-background-shade);
  justify-content: center;
  align-items: center;
  display: flex;
}

.profile__sign {
  color: var(--color-text);
  margin: 0 auto 1rem;
  display: block;
}

@keyframes sign {
  from {
    stroke-dashoffset: calc(var(--path-length) * 1px);
  }

  to {
    stroke-dashoffset: 0;
  }
}

.profile__sign path {
  animation: 2s .2s forwards sign;
}

.profile__avatar {
  --ava-size: 256px;
  --ava-border: .3rem;
  width: var(--ava-size);
  height: var(--ava-size);
  transform-style: preserve-3d;
  margin: 0 auto;
  transition: transform .5s ease-in-out;
  position: relative;
  transform: rotate3d(.01, 1, 0, 0);
}

.profile__avatar--front {
  transform: rotate3d(.01, 1, 0, 0);
}

.profile__avatar--back {
  transform: rotate3d(0, 1, 0, 180deg);
}

.profile__avatar > * {
  width: var(--ava-size);
  height: var(--ava-size);
  top: calc(-1 * var(--ava-border));
  left: calc(-1 * var(--ava-border));
  border-width: var(--ava-border);
  background-color: var(--color-ava-background);
  border-radius: var(--ava-size);
  backface-visibility: hidden;
  border-style: solid;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.profile__avatar-front {
  transform: rotate3d(0, 1, 0, 0);
}

.profile__picture-button {
  appearance: none;
  cursor: pointer;
  border-color: var(--color-ava-background);
  transition: border-color .2s;
}

.profile__picture-button:hover, .profile__picture-button:focus {
  border-color: var(--color-accent);
}

.profile__picture {
  transform: translate(calc(-1 * var(--ava-border)), calc(-1 * var(--ava-border)));
}

.profile__avatar-back {
  border-color: var(--color-accent);
  flex-direction: column;
  display: flex;
  transform: rotate3d(0, 1, 0, 180deg);
}

.profile__avatar-back-title {
  color: var(--color-ava-background-text);
  text-align: center;
}

.profile__links-cloud {
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
  display: grid;
}

.profile-link {
  text-align: center;
  margin: 0 auto;
  display: block;
}

.profile__avatar-back-button {
  appearance: none;
  background-color: var(--color-primary);
  color: var(--color-text);
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border: none;
  border-radius: .25rem;
  margin: 0 auto;
  padding: .8rem 1rem;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color .2s;
  display: block;
}

.profile__avatar-back-button:hover, .profile__avatar-back-button:focus {
  background-color: var(--color-primary-focus);
}

.profile__subtext {
  max-width: 400px;
  text-align: center;
  padding: 0 1rem;
}

/*# sourceMappingURL=index.c087fe8f.css.map */
